.App {
  text-align: center;
  font-family: 'Courier New', Courier, monospace;
  text-transform: uppercase;
}

.landing-wrapper {
  display: block;
}

.moon-logo {
  height: 26vmin;
  pointer-events: none;
  backface-visibility: hidden;
  position: absolute;
    top: 16.3vmin;
    left: center;
}

.sun-logo {
  height: 40vmin;
  pointer-events: none;
  backface-visibility: hidden;
  position: absolute;
    top: 10vmin;
    left: center;
}

.inactive {
  display: none;
}

.main-wrapper {
  background-color:#ffffcc;
}

.header-wrapper {
  background-color: transparent;
  position: fixed;
  top: 0px;
  width: 100%;
  height: 15%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: min-content
}

.header-logo {
  height: 15vmin;
  left: 45%;
}

.header-title {
  font-size: calc(10px + 2vmin);
  padding: 0px 10px;
  margin: 0px;
  text-align: left;
}

.header-subtitle {
  font-size: calc(10px + 0.5vmin);
  padding: 0px 10px;
  margin: 0px;
  text-align: left;
}

.spacer {
  height: 15vmin;
}

.content-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.menu-section-title {
  text-decoration: underline;
}

.menu-section-items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 90%;
}

.menu-section-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 45%;
}

.menu-item-title{
  font-size: calc(10px + 1vmin);
  font-weight: bold;
  padding: 0;
  margin: 10px 0 0 0;
}

.menu-item-description{
  font-size: calc(10px + 1vmin);
}

@media (prefers-reduced-motion: no-preference) {
  .moon-logo {
    animation: spin-front infinite 5s linear;
  }
  .sun-logo {
    animation: spin-back infinite 5s linear;
  }
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-title {
  position: absolute;
    top: 55vmin;
  left: center;
  font-size: calc(10px + 3vmin);
}


.btn-enter {
  color: gold;
  background: none;
  position: absolute;
    top: 70vmin;
  left: center;
  display:inline-block;
  border:none;
  font-family: 'Courier New', Courier, monospace;
  font-size: calc(10px + 2vmin)
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@keyframes spin-front {
  0% {
    transform: rotate3d(0, 1, 0, 0deg);
  }
  50% {
    transform: rotate3d(0, 1, 0, 180deg);
  }
  100% {
    transform: rotate3d(0, 1, 0, 360deg);
  }
}

@keyframes spin-back {
  0% {
    transform: rotate3d(0, 1, 0, 180deg);
  }
  50% {
    transform: rotate3d(0, 1, 0, 360deg);
  }
  100% {
    transform: rotate3d(0, 1, 0, 180deg);
  }
}
